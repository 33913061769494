.passwordStrengthBlock {
    display: flex;
    gap: 10px;
}
.passwordStrengthBlock > div {
    height: 5px;
    background-color: #a1a1a1;
    width: calc(100% / 3 - 10px);
    border-radius: 5px;
    margin-top: 8px;
}
.passwordStrengthBlock.weak > div:nth-of-type(1) {
    background-color: red;
}
.passwordStrengthBlock.medium > div:nth-of-type(1),
.passwordStrengthBlock.medium > div:nth-of-type(2) {
    background-color: orange;
}
.passwordStrengthBlock.high > div {
    background-color: green;
}
